import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const Hero = () => {
    const { placeholderImage } = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(relativePath: { eq: "banner2.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 50, placeholder: BLURRED, webpOptions: { quality: 70 })
                    }
                }
            }
        `
    );

    const image = getImage(placeholderImage);

    return (
        <BgImage image={image} alt="banner" className="min-h-screen bg-top">
            <div className="absolute w-full sm:w-6/12 md:w-4/12 lg:w-3/12 md:left-10 h-screen bg-black bg-opacity-60">
                <div>
                    <div
                        className="relative p-8 top-40 text-5xl sm:text-6xl text-pink-500 uppercase leading-tight font-bold"
                        style={{ fontFamily: 'Open Sans' }}
                    >
                        The Future of Dairy Farming is Here
                        <div className="text-3xl leading-tight text-white normal-case italic font-bold">
                            Available Now in the South Island, NZ
                        </div>
                        <div className="text-center">
                            <a
                                href="https://vimeo.com/254382799"
                                target="_blank"
                                rel="noreferrer"
                                alt="intro video"
                                className="text-2xl leading-tight text-blue-400 normal-case font-bold"
                            >
                                Watch Video
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </BgImage>
    );
};

export default Hero;
