/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import '@fontsource/poppins';
import '@fontsource/raleway';
import '@fontsource/open-sans';
import Hero from './hero';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Hero />
            <div className="md:max-w-6xl sm:px-16 m-auto">
                <main>{children}</main>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics"
            >
                By clicking "Accept", you agree to the storing of cookies on your device to enhance site navigation,
                analyze site usage, and assist in our marketing efforts..
            </CookieConsent>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
